import type { FC } from 'react';
import type { IconProps } from '@/common/types/icon';

const RightDiagonal: FC<Omit<IconProps, 'size'>> = (props) => {
  const { fill, ...rest } = props;
  return (
    <svg
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="10"
      viewBox="0 0 11 10"
      fill="none"
    >
      <path d="M1.5 8.89941L9.27817 1.12124" stroke={fill || '#212126'} strokeWidth="1.5" />
      <path
        d="M9.57129 6.48535L9.59748 0.854687L3.91443 0.828498"
        stroke="#212126"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default RightDiagonal;
