import { motion } from 'framer-motion';
import DesktopView from '@/components/unit/DesktopView';
import useVideoInteration from '../hooks/useVideoInteration';
import { IVideoLayoutDesktop } from '../type';
import DynamicVideoButton from '../DynamicVideoButton';

export default function VideoLayoutDesktop({
  containerClass,
  videoClass,
  videoSource
}: IVideoLayoutDesktop) {
  const { hidden, hanldeOnMouseEnter, hanldeOnMouseLeave, isPlaying, pause, play, setRefs } =
    useVideoInteration();
  return (
    <DesktopView>
      <article className={containerClass} onMouseLeave={hanldeOnMouseLeave}>
        <motion.video
          autoPlay
          playsInline
          muted
          initial={{ opacity: 0 }}
          className={videoClass}
          ref={setRefs}
          loop
          onMouseEnter={hanldeOnMouseEnter}
          whileInView={{ opacity: 1 }}
        >
          <track kind="captions" />
          <source src={videoSource} />
        </motion.video>
        <DynamicVideoButton
          isHidden={hidden}
          isPlaying={isPlaying}
          pauseClick={pause}
          playClick={play}
        />
      </article>
    </DesktopView>
  );
}
