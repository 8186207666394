import { useDeviceClasses } from '@/common/hooks/useDeviceClasses';
import Pause from '@/common/icons/Pause';
import Play from '@/common/icons/Play';
import { twJoin } from 'tailwind-merge';
import { useState } from 'react';
import { useLayoutEffect } from '@/common/hooks/useLayoutEffect';
import { useIsMobile } from '@/common/contexts/IsMobileContext';
import OperationComponent from './OperationComponent';

interface IDynamicVideoButtonProps {
  pauseClick: () => void;
  playClick: () => void;
  isPlaying: boolean;
  isHidden: boolean;
}

export default function DynamicVideoButton({
  pauseClick,
  playClick,
  isPlaying,
  isHidden
}: IDynamicVideoButtonProps) {
  const isMobile = useIsMobile();
  const styles = {
    container: useDeviceClasses({
      common: twJoin(
        'absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2',
        isHidden ? 'hidden' : ''
      )
    }),
    icon: useDeviceClasses({ mobile: 'h-14v w-13v' })
  };

  const DynamicIconComponent = isPlaying ? (
    <Pause className={styles.icon} />
  ) : (
    <Play className={styles.icon} />
  );
  const ignoreClickTime = 200;
  const [canClick, setCanClick] = useState(false);
  const handleMobileButtonClick = () => {
    // hidden이 풀려 있는 경우에만 클릭 이벤트 실행
    if (!isHidden && canClick) {
      isPlaying ? pauseClick() : playClick();
    }
  };
  const handleButtonClick = () => {
    // hidden이 풀려 있는 경우에만 클릭 이벤트 실행
    isPlaying ? pauseClick() : playClick();
  };

  useLayoutEffect(() => {
    // isHidden이 변경되면 클릭 가능 상태 초기화
    if (!isHidden)
      setTimeout(() => {
        setCanClick(true);
      }, ignoreClickTime);
    else {
      setCanClick(false);
    }
  }, [isHidden]);
  return (
    <button
      id="videoButton"
      aria-label="videoButton"
      type="button"
      onClick={isMobile ? handleMobileButtonClick : handleButtonClick}
      className={styles.container}
    >
      <OperationComponent Icon={DynamicIconComponent} />
    </button>
  );
}
