import layout from '@/common/constants/layout';
import { useDeviceClasses } from '@/common/hooks/useDeviceClasses';
import { ReactNode } from 'react';
import { twJoin } from 'tailwind-merge';

export default function OperationComponent({ Icon }: { Icon: ReactNode }) {
  return (
    <div
      className="justiy-center relative flex items-center rounded-100"
      style={{ backdropFilter: 'blur(4px)' }}
    >
      <div
        className={useDeviceClasses({
          common: 'rounded-100 bg-videoButton-black opacity-40',
          desktop: twJoin('h-60 w-60', layout.mixins.displayCenter),
          mobile: 'h-44v w-44v'
        })}
      />
      <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">{Icon}</div>
    </div>
  );
}
