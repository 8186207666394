import type { IconProps } from '@/common/types/icon';
import type { FC } from 'react';

const Pause: FC<Omit<IconProps, 'size'>> = (props) => {
  const { ...rest } = props;
  return (
    <svg
      {...rest}
      aria-label="Pause"
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 0C0.671573 0 0 0.671573 0 1.5V18.5C0 19.3284 0.671573 20 1.5 20H4.5C5.32843 20 6 19.3284 6 18.5V1.5C6 0.671573 5.32843 0 4.5 0H1.5ZM13.5 0C12.6716 0 12 0.671573 12 1.5V18.5C12 19.3284 12.6716 20 13.5 20H16.5C17.3284 20 18 19.3284 18 18.5V1.5C18 0.671573 17.3284 0 16.5 0H13.5Z"
        fill="#F7F7F7"
      />
    </svg>
  );
};

export default Pause;
