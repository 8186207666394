import { useRouter } from 'next/router';
import VideoLayoutMobile from './Mobile';
import VideoLayoutDesktop from './Desktop';

interface VideoViewProps {
  videoSource: string;
  containerClass: string;
  videoClass: string;
}

export default function VideoLayout({ videoSource, containerClass, videoClass }: VideoViewProps) {
  const router = useRouter();
  const isHome = router.pathname === '/';

  const commonProps = {
    videoSource,
    containerClass,
    videoClass
  };
  return (
    <>
      <VideoLayoutMobile {...commonProps} isHome={isHome} />
      <VideoLayoutDesktop {...commonProps} />
    </>
  );
}
