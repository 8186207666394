import { useLayoutEffect } from '@/common/hooks/useLayoutEffect';
import { useVideo } from '@/common/hooks/useVideo';
import { useCallback, useState } from 'react';
import { useInView } from 'react-intersection-observer';

interface IuseVideoInteration {
  outerClickLeave?: boolean;
}
export default function useVideoInteration({ outerClickLeave = false }: IuseVideoInteration = {}) {
  const [hidden, setHidden] = useState(true);
  const handleButtonHiddenChange = (value: boolean) => setHidden(value);
  const hanldeOnMouseEnter = () => handleButtonHiddenChange(false);
  const hanldeOnMouseLeave = () => handleButtonHiddenChange(true);
  const handleTouchStart = () => handleButtonHiddenChange(false);
  const handleTouchEnd = () => handleButtonHiddenChange(true);
  const { videoRef, isPlaying, pause, play } = useVideo();
  const { ref: inViewRef, inView } = useInView({ triggerOnce: true });
  const setRefs = useCallback(
    (node: HTMLVideoElement) => {
      inViewRef(node);
      videoRef.current = node;
    },
    [inViewRef, videoRef]
  );

  const handleGlobalClick = (event: MouseEvent | TouchEvent) => {
    // 비디오 외의 영역을 클릭했을 때 handleOnMouseLeave 실행
    const videoButtonElement = (event.target as HTMLElement)?.closest('#videoButton');
    if (
      videoRef.current &&
      !videoButtonElement &&
      !videoRef.current.contains(event.target as Node)
    ) {
      hanldeOnMouseLeave();
    }
  };

  useLayoutEffect(() => {
    if (!outerClickLeave) return;
    // 전역 클릭 이벤트 리스너 등록
    document.addEventListener('click', handleGlobalClick);

    // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
    return () => {
      document.removeEventListener('click', handleGlobalClick);
    };
  }, [outerClickLeave]);

  return {
    hanldeOnMouseLeave,
    hanldeOnMouseEnter,
    handleTouchStart,
    handleTouchEnd,
    hidden,
    isPlaying,
    pause,
    play,
    inView,
    setRefs
  };
}
