import MobileView from '@/components/unit/MobileView';
import { MotionProps, motion } from 'framer-motion';
import { IVideoLayoutProps } from '../type';
import DynamicVideoButton from '../DynamicVideoButton';
import useVideoInteration from '../hooks/useVideoInteration';

interface IVideoLayoutMobile extends IVideoLayoutProps {
  isHome: boolean;
}
export default function VideoLayoutMobile({
  containerClass,
  videoClass,
  videoSource,
  isHome
}: IVideoLayoutMobile) {
  const { hidden, handleTouchStart, handleTouchEnd, inView, isPlaying, pause, play, setRefs } =
    useVideoInteration({ outerClickLeave: true });
  const duration = 0.5;
  const amount = 'all';

  const motionConfig: MotionProps = { transition: { duration }, viewport: { amount } };

  return (
    <MobileView>
      <motion.article
        className={containerClass}
        whileInView={!isHome ? { paddingLeft: 0, paddingRight: 0 } : undefined}
        {...motionConfig}
      >
        {/* ty ? handleTouchEnd :  */}
        <motion.video
          onTouchEnd={hidden ? handleTouchStart : handleTouchEnd}
          {...motionConfig}
          autoPlay
          playsInline
          muted
          ref={setRefs}
          className={videoClass}
          loop
        >
          <track kind="captions" />
          {inView && (
            <>
              {/* <source src={videoSource} media="(min-width: 768px)" />
          <source src={videoSource} media="(min-width: 1280px)" /> */}
              <source src={videoSource} type="video/mp4" />
            </>
          )}
        </motion.video>
        <DynamicVideoButton
          isHidden={hidden}
          isPlaying={isPlaying}
          pauseClick={pause}
          playClick={play}
        />
      </motion.article>
    </MobileView>
  );
}
