import { useRef } from 'react';
import { useBoolean } from '../useBoolean';
import { useLayoutEffect } from '../useLayoutEffect';

const useVideo = () => {
  const videoRef = useRef<HTMLVideoElement>();
  const { on: handlePlay, off: handlePause, value: isPlaying } = useBoolean();

  const play = () => {
    if (!isPlaying) videoRef.current!.play();
  };

  const pause = () => {
    if (isPlaying) {
      videoRef.current!.pause();
      // videoRef.current!.muted = false;
    }
  };

  const handleEventListener = (type: 'addEventListener' | 'removeEventListener') => {
    if (!videoRef.current) return;
    videoRef.current[type]('play', handlePlay);
    videoRef.current[type]('pause', handlePause);
  };
  useLayoutEffect(() => {
    handleEventListener('addEventListener');
    return () => {
      handleEventListener('removeEventListener');
    };
  }, []);

  return { play, pause, isPlaying, videoRef };
};

export default useVideo;
