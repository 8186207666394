import type { IconProps } from '@/common/types/icon';
import type { FC } from 'react';
import { twJoin } from 'tailwind-merge';

const Play: FC<Omit<IconProps, 'size'>> = (props) => {
  const { className, ...rest } = props;
  return (
    <svg
      {...rest}
      aria-label="Play"
      className={twJoin(className, 'relative left-2')}
      width="18"
      height="22"
      viewBox="0 0 18 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 19.3996V2.60044C0 1.03127 1.72455 0.0733026 3.05678 0.902436L16.5529 9.302C17.8106 10.0847 17.8106 11.9153 16.5529 12.698L3.05678 21.0976C1.72455 21.9267 0 20.9687 0 19.3996Z"
        fill="#F7F7F7"
      />
    </svg>
  );
};
export default Play;
